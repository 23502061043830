var config = window.config = {};

// Config reference element
var $ref = $ ("#ref");

// Configure responsive bootstrap toolkit
config.ResponsiveBootstrapToolkitVisibilityDivs = {
	'xs': $ ('<div class="device-xs hidden-sm-up"></div>'),
	'sm': $ ('<div class="device-sm hidden-xs-down hidden-md-up"></div>'),
	'md': $ ('<div class="device-md hidden-sm-down hidden-lg-up"></div>'),
	'lg': $ ('<div class="device-lg hidden-md-down hidden-xl-up"></div>'),
	'xl': $ ('<div class="device-xl hidden-lg-down"></div>')
};

ResponsiveBootstrapToolkit.use ('Custom', config.ResponsiveBootstrapToolkitVisibilityDivs);

//validation configuration
config.validations = {
	debug: true,
	errorClass: 'has-error',
	validClass: 'success',
	errorElement: "span",

	// add error class
	highlight: function (element, errorClass, validClass) {
		$ (element).parents ("div.form-group")
			.addClass (errorClass)
			.removeClass (validClass);
	},

	// add error class
	unhighlight: function (element, errorClass, validClass) {
		$ (element).parents (".has-error")
			.removeClass (errorClass)
			.addClass (validClass);
	},

	// submit handler
	submitHandler: function (form) {
		form.submit ();
	}
};

//delay time configuration
config.delayTime = 50;

// chart configurations
config.chart = {};

/***********************************************
 *        Animation Settings
 ***********************************************/
function animate(options) {
	var animationName = "animated " + options.name;
	var animationEnd = "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend";
	$ (options.selector)
		.addClass (animationName)
		.one (animationEnd,
			function () {
				$ (this).removeClass (animationName);
			}
		);
}

$ (function () {
	var $itemActions = $ (".item-actions-dropdown");

	$ (document).on ('click', function (e) {
		if (!$ (e.target).closest ('.item-actions-dropdown').length) {
			$itemActions.removeClass ('active');
		}
	});

	$ ('.item-actions-toggle-btn').on ('click', function (e) {
		e.preventDefault ();

		var $thisActionList = $ (this).closest ('.item-actions-dropdown');

		$itemActions.not ($thisActionList).removeClass ('active');

		$thisActionList.toggleClass ('active');
	});
});

function setSameHeights($container) {

	$container = $container || $ ('.sameheight-container');

	var viewport = ResponsiveBootstrapToolkit.current ();

	$container.each (function () {

		var $items = $ (this).find (".sameheight-item");

		// Get max height of items in container
		var maxHeight = 0;

		$items.each (function () {
			$ (this).css ({height: 'auto'});
			maxHeight = Math.max (maxHeight, $ (this).innerHeight ());
		});

		// Set heights of items
		$items.each (function () {
			// Ignored viewports for item
			var excludedStr = $ (this).data ('exclude') || '';
			var excluded = excludedStr.split (',');

			// Set height of element if it's not excluded on 
			if (excluded.indexOf (viewport) === -1) {
				$ (this).innerHeight (maxHeight);
			}
		});
	});
}

$ (function () {
	animate ({
		name: 'flipInY',
		selector: '.error-card > .error-title-block'
	});

	setTimeout (function () {
		var $el = $ ('.error-card > .error-container');

		animate ({
			name: 'fadeInUp',
			selector: $el
		});

		$el.addClass ('visible');
	}, 500);
});

$ (function () {

	$ ('#sidebar-menu, #customize-menu').metisMenu ({
		activeClass: 'open'
	});

	$ ('#sidebar-collapse-btn').on ('click', function (event) {
		event.preventDefault ();

		$ ("#app").toggleClass ("sidebar-open");
	});

	$ ("#sidebar-overlay").on ('click', function () {
		$ ("#app").removeClass ("sidebar-open");
	});

});
$ (function () {

	$ ('.actions-list > li').on ('click', '.check', function (e) {
		e.preventDefault ();

		$ (this).parents ('.tasks-item')
			.find ('.checkbox')
			.prop ("checked", true);

		// removeActionList ();
	});

});

$ (function () {
	$ ('.nav-profile > li > a').on ('click', function () {
		var $el = $ (this).next ();

		animate ({
			name: 'flipInX',
			selector: $el
		});
	});
});

$ (function () {
	// Local storage settings
	var themeSettings = getThemeSettings ();

	// Elements

	var $app = $ ('#app');
	var $styleLink = $ ('#theme-style');
	var $customizeMenu = $ ('#customize-menu');

	// Color switcher
	var $customizeMenuColorBtns = $customizeMenu.find ('.color-item');

	// Position switchers
	var $customizeMenuRadioBtns = $customizeMenu.find ('.radio');

	/************************************************
	 *            Initial State
	 *************************************************/

	setThemeSettings ();

	setSameHeights ();

	/************************************************
	 *               Events
	 *************************************************/

	// set theme type
	$customizeMenuColorBtns.on ('click', function () {
		themeSettings.themeName = $ (this).data ('theme');

		setThemeSettings ();
	});

	$customizeMenuRadioBtns.on ('click', function () {

		var optionName = $ (this).prop ('name');
		themeSettings[optionName] = $ (this).val ();

		setThemeSettings ();
	});

	function setThemeSettings() {
		setThemeState ()
			.delay (config.delayTime)
			.queue (function (next) {

				setThemeColor ();
				setThemeControlsState ();
				saveThemeSettings ();

				$ (document).trigger ("themechange");

				next ();
			});
	}

	/************************************************
	 *         Update theme based on options
	 *************************************************/

	function setThemeState() {
		// set theme type
		if (themeSettings.themeName) {
			$styleLink.attr ('href', window.cssPath + '/app-' + themeSettings.themeName + '.css');
		}
		else {
			$styleLink.attr ('href', window.cssPath + '/app.css');
		}
		// App classes
		$app.removeClass ('header-fixed footer-fixed sidebar-fixed');

		// set header
		$app.addClass (themeSettings.headerPosition);

		// set footer
		$app.addClass (themeSettings.footerPosition);

		// set footer
		$app.addClass (themeSettings.sidebarPosition);

		return $app;
	}

	/************************************************
	 *         Update theme controls based on options
	 *************************************************/

	function setThemeControlsState() {
		// set color switcher
		$customizeMenuColorBtns.each (function () {
			if ($ (this).data ('theme') === themeSettings.themeName) {
				$ (this).addClass ('active');
			}
			else {
				$ (this).removeClass ('active');
			}
		});

		// set radio buttons
		$customizeMenuRadioBtns.each (function () {
			var name = $ (this).prop ('name');
			var value = $ (this).val ();

			if (themeSettings[name] === value) {
				$ (this).prop ("checked", true);
			}
			else {
				$ (this).prop ("checked", false);
			}
		});
	}

	/************************************************
	 *         Update theme color
	 *************************************************/
	function setThemeColor() {
		config.chart.colorPrimary = tinycolor ($ref.find (".chart .color-primary").css ("color"));
		config.chart.colorSecondary = tinycolor ($ref.find (".chart .color-secondary").css ("color"));
	}

	/************************************************
	 *            Storage Functions
	 *************************************************/

	function getThemeSettings() {
		var settings = (localStorage.getItem ('themeSettings')) ? JSON.parse (localStorage.getItem ('themeSettings')) : {};

		settings.headerPosition = settings.headerPosition || '';
		settings.sidebarPosition = settings.sidebarPosition || '';
		settings.footerPosition = settings.footerPosition || '';

		return settings;
	}

	function saveThemeSettings() {
		localStorage.setItem ('themeSettings', JSON.stringify (themeSettings));
	}

});

/**
 * Makes slug from input string
 *
 * @param text
 * @param trimSpaces
 *
 * @returns {string}
 */
function slugify(text, trimSpaces) {
	text = text.toString ().toLowerCase ();

	if (trimSpaces)
		text = text.trim ();

	return text.replace (/ /g, '-')
		.replace (/[àáâãäå]/g, "a")
		.replace (/[čç]/g, "c")
		.replace (/[ěèéêë]/g, "e")
		.replace (/[ùúûüů]/g, "u")
		.replace (/[ìíîï]/g, "i")
		.replace (/[ňńñ]/g, "n")
		.replace (/[òóôõö]/g, "o")
		.replace (/[ýÿ]/g, "y")
		.replace (/œ/g, "oe")
		.replace (/ď/g, "d")
		.replace (/ř/g, "r")
		.replace (/š/g, "s")
		.replace (/ž/g, "z")
		.replace (/ť/g, "t")
		.replace (/æ/g, "ae")
		.replace (/[^a-z0-9\-]+/g, '')
		.replace (/--+/g, '-');
}

// bind some custom methods
$ (document).ready (function () {

	$ ("body").addClass ("loaded");

	var notification = $ ('.notification');
	if (notification.length) {

		notification.click (function () {
			$ (this).fadeOut ('fast');
		});

		setTimeout (function () {
			notification.fadeOut (1500)
		}, 5 * 1000)
	}

	var previousTitle, previousMessage;

	$ ('.confirm').click (function (e) {

		var titleElement = $ ('#confirmation-title');
		var messageElement = $ ('#confirmation-message');

		previousTitle = titleElement.text ();
		previousMessage = messageElement.text ();

		var confirmTitle = $ (this).data ('title');
		var confirmMessage = $ (this).data ('message');

		var tag = $ (this).prop ('tagName');
		if (tag === 'A') {
			var href = $ (this).attr ('href');

			$ ('#confirm-button').attr ('href', href);
		} else if (tag === 'BUTTON') {
			var form = $ (this).closest ('form');
			$ ('#confirm-button').click (function () {
				form.submit ();
			})
		}

		if (confirmTitle)
			titleElement.text (confirmTitle);

		if (confirmMessage)
			messageElement.text (confirmMessage);

		$ ('#confirm-modal').modal ('show');

		$ ('.main-wrapper').addClass ('blur');

		e.preventDefault ();
	});

	$ ('#confirm-modal').on ('hidden.bs.modal', function () {
		$ ('#confirmation-title').text (previousTitle);
		$ ('#confirmation-message').text (previousMessage);
		$ ('.main-wrapper').removeClass ('blur');
	});

	$ ('#lockUrl').change (function () {
		$ ('.slug-source').keyup ();
	});

	$ ('.slug-target').keyup (function () {
		$ (this).val (slugify ($ (this).val (), false));
		$ ('#lockUrl').prop ('checked', true);
	});

	$ ('.slug-source').keyup (function () {
		var source = $ (this);
		var target = $ ('.slug-target');
		var locked = $ ('#lockUrl');

		if (!locked.is (':checked'))
			target.val (slugify (source.val (), true));
	});

	$ (function () {
		$ ('[data-toggle="tooltip"]').tooltip ()
	});

	// find if any error occured and mark tab with that error
	$ ('.has-error').each (function () {
		var error = $ (this);
		var tabCard = error.closest ('.tab-pane');

		$ ("#" + tabCard.prop ('id') + "-tab").addClass ('tab-with-error');
	});

	$ ('#removeTitlePhoto').click (function () {
		$ ('#titlePhotoContainer').remove ();
		// $('existingTitlePhoto').val(null);
	});

	$ ('form').areYouSure ();
});

$ ('.checkbox').change (function () {
	var e = $ (this);
	var checked = e.is (':checked');
	var id = e.data ('id');

	$ ('.child-' + id).prop ('checked', checked);

	// check also parent
	if (e.hasClass ('child-checkbox') && checked) {
		var parent = e.data ('parent');
		$ ('#checkbox-parent-' + parent).prop ('checked', true);
	}
});

$ ('.has-subpages').click (function (e) {
	if (!$ (e.target).is ("div"))
		return;

	var id = $ (this).data ('id');
	$ ('.parent-page-' + id).toggleClass ('hidden-xs-up');
})